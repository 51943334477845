import { useState, useEffect } from "react";
import { Calendar } from "@demark-pro/react-booking-calendar";
import { fetchCalendar } from './api';
import LoadingCalendar from "./Loading";
// import "@demark-pro/react-booking-calendar/dist/react-booking-calendar.css";
// import "./C.css";
// import './BookingCalendar.css'; // Import the CSS styles

function extractReservedDates(data) {
  const days = data.calendar.days;
  const reservedRanges = [];
  let startDate = null;

  for (let i = 0; i < days.length; i++) {
    const day = days[i];

    if (!day.available) {
      if (startDate === null) {
        startDate = new Date(day.date);
      }
    } else {
      if (startDate !== null) {
        reservedRanges.push({
          startDate: new Date(startDate.setDate(startDate.getDate() + 1)).toISOString().split('T')[0],
          endDate: new Date(new Date(days[i - 1].date).setDate(new Date(days[i - 1].date).getDate() + 1)).toISOString().split('T')[0]
        });
        startDate = null;
      }
    }
  }

  if (startDate !== null) {
    reservedRanges.push({
      startDate: new Date(startDate.setDate(startDate.getDate() + 1)).toISOString().split('T')[0],
      endDate: new Date(new Date(days[days.length - 1].date).setDate(new Date(days[days.length - 1].date).getDate() + 1)).toISOString().split('T')[0]
    });
  }

  return reservedRanges;
}

function getEndDate(dateWithAddedMonths) {
  return `${dateWithAddedMonths.getFullYear()}-${String(dateWithAddedMonths.getMonth() + 1).padStart(2, '0')}-${String(dateWithAddedMonths.getDate()).padStart(2, '0')}`;
}

function addMonths(date, months) {
  const result = new Date(date);
  result.setMonth(result.getMonth() + months);
  return result;
}

function getCurrentDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export default function BookingCalendar({ maxGuests, propertySlug, propertyId }) {
  const [selected, setSelected] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reserved, setReserved] = useState([]);
  const [guestCount, setGuestCount] = useState(1); // Default to 1 guest

  const [startDate, setStartDate] = useState(getCurrentDate());
  const [endDate, setEndDate] = useState(getEndDate(addMonths(new Date(startDate), 11)));

  const getCalendarData = async (from, to) => {
    setLoading(true);
    setError(null);
    try {
      const data = await fetchCalendar(propertyId, from, to);
      setCalendarData(data.calendar.days);
      const reserved = extractReservedDates(data);
      setReserved(reserved);
    } catch (err) {
      setError('Sorry something went wrong, refresh the page and try again please.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCalendarData(startDate, endDate);
  }, [startDate, endDate]);

  function formatDates(datesArray) {
    return datesArray.map(date => {
      return date.toISOString().split('T')[0]; // Convert to ISO and take the date part
    });
  }

  // Function to get the payment URL based on the listing ID
const getPaymentUrl = (lid, checkIn, checkOut, guestCount, propertySlug) => {
    if (lid === "81620") {
        return `https://vidapalace.bookeddirectly.com/g/payment/new?check_in=${checkIn}&check_out=${checkOut}&number_of_guests=${guestCount}&property_slug=07b176`;
    } else {
        return `https://zenvillajaco.bookeddirectly.com/g/payment/new?check_in=${checkIn}&check_out=${checkOut}&number_of_guests=${guestCount}&property_slug=${propertySlug}`;
    }
};

  const handleBook = () => {
    const formattedDates = formatDates(selected);
    const checkIn = formattedDates[0];
    const checkOut = formattedDates[1];
    // https://vidapalace.bookeddirectly.com/g/payment/new?check_in=2024-10-28&check_out=2024-10-30&number_of_guests=1&property_slug=07b176
    // const bookingUrl = `https://zenvillajaco.bookeddirectly.com/g/payment/new?check_in=${checkIn}&check_out=${checkOut}&number_of_guests=${guestCount}&property_slug=${propertySlug}`;
    const bookingUrl = getPaymentUrl(propertyId, checkIn, checkOut, guestCount, propertySlug);

    window.location.href = bookingUrl; // Navigate to booking URL
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const isDateRangeSelected = selected.length === 2; // Check if both check-in and check-out dates are selected

  return (
    <div className="booking-container">
      <div className="">
        <Calendar
          selected={selected}
          reserved={reserved}
          range={true}
          protection={true}
          options={{ locale: "en", weekStartsOn: 0, useAttributes: true }}
          onChange={setSelected}
          onOverbook={(date, type) => alert(type)}
        />
      </div>
      <div className="controls-section">
        <div className="guest-dropdown">
          <label>
            <select value={guestCount} onChange={(e) => setGuestCount(Number(e.target.value))}>
              {Array.from({ length: maxGuests }, (_, i) => i + 1).map(num => (
                <option key={num} value={num}>{num} guest{num > 1 && 's'}</option>
              ))}
            </select>
          </label>
        </div>
        <div className="book-button-container">
          <button 
            className="instant-book" 
            onClick={handleBook}
            disabled={!isDateRangeSelected} // Disable button if dates are not selected
          >
            Instant Book
          </button>
          <div className="charge-text">You won't be charged yet</div>
        </div>
      </div>
    </div>
  );
}
