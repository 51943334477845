import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropertyCard from './PropertyCard';
import BookingCalendar from './BookingCalendar';
import "./dark-theme.css";
import "./modal.css"; // Import modal-specific styles

const MyReactButton = ({ dataValue, maxGuests, propertySlug, propertyId, theme }) => {
    const [openContent, setOpenContent] = useState(null); // Track which content is open

    const handleOpenModal = (contentType) => {
        setOpenContent(contentType); // Set the content to display
    };

    const handleCloseModal = () => {
        setOpenContent(null); // Close the modal
    };

    return (
        <div className={`my-react-button ${theme}`}>
            <div className="button-container">
                <button 
                    className="button instant-book" 
                    onClick={() => handleOpenModal('calendar')}
                >
                    Check Availability
                </button>

                <button 
                    className="button info-button" 
                    onClick={() => handleOpenModal('info')}
                >
                    View Property Details
                </button>
            </div>

            {/* Modal */}
            {openContent && (
                <div className="modal-overlay" onClick={handleCloseModal}>
                    <div 
                        className="modal-content" 
                        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
                    >
                        <button className="close-button" onClick={handleCloseModal}>
                            &times;
                        </button>

                        {openContent === 'calendar' && (
                            <BookingCalendar 
                                maxGuests={maxGuests} 
                                propertySlug={propertySlug} 
                                propertyId={propertyId} 
                            />
                        )}

                        {openContent === 'info' && (
                            <PropertyCard propertyId={propertyId} />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

// Render the component for each DOM element
document.addEventListener('DOMContentLoaded', () => {
    const elements = document.querySelectorAll('.my-react-button');
    elements.forEach((element) => {
        const dataValue = element.getAttribute('data-value');
        const maxGuests = element.getAttribute('data-max-guests');
        const propertySlug = element.getAttribute('data-property-slug');
        const propertyId = element.getAttribute('data-property-id');
        const theme = element.getAttribute('data-theme');

        ReactDOM.render(
            <MyReactButton 
                dataValue={dataValue} 
                maxGuests={maxGuests} 
                propertySlug={propertySlug} 
                propertyId={propertyId} 
                theme={theme} 
            />, 
            element
        );
    });
});
