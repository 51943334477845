import React, { useState } from 'react';
// import './PropertyCard.css'; // Ensure you have this CSS file for styling
// import { properties } from './properties';
import { getSvg } from './svgs';
const properties = [
    {
      "property_id":"81767",
      "property_title": "Private resort style pool, AC, WI-FI, clean home",
      "website_name": "Zen Villa 1",
      "property_name": "Zen Villa Beach",
      "location": "Jaco, Jaco",
      "maps": "insert_map_address_for_xframe",
      "type": "Private beach home",
      "amenities":{
          "oven": "Oven",
          "microwave": "Microwave",
          "toaster": "Toaster",
          "coffee_maker": "Coffee Maker",
          "dishes_utensils": "Dishes & Utensils",
          "dishwasher": "Dishwasher",
          "kitchen": "Kitchen",
          "pantry_items": "Pantry Items",
          "refrigerator": "Refrigerator",
          "stove": "Stove",
          "internet": "Internet",
          "air_conditioning": "Air Conditioning",
          "washing_machine": "Washing Machine",
          "clothes_dryer": "Clothes Dryer",
          "linens": "Linens Provided",
          "towels": "Towels Provided",
          "free_parking_on_premises": "Free parking on premises",
          "free_street_parking": "Free street parking",
          "hair_dryer": "Hair Dryer",
          "iron_board": "Iron & Board",
          "television": "Television",
          "lawn_garden": "Lawn / Garden",
          "outdoor_grill": "Outdoor Grill",
          "fire_extinguisher": "Fire extinguisher",
          "smoke_detector": "Smoke detector"
      },      
      "bedrooms": 4,
      "beds": 5,
      "bathrooms": 2.5,
      "max_guests": 12,
      "short_description": `<h2>Welcome to Zen Villa 1</h2>
            <p>
                A completely private house equipped with everything you need to fully enjoy your vacation in Jacó, located 
                at an 8-minute drive to Jaco town and the beach. Inside a valley, this new developing community is very safe 
                and surrounded by nature, mountain views, and the beautiful sounds of the jungle.
            </p>`,
      "description": `
        
            <h2>Welcome to Zen Villa 1</h2>
            <p>
                A completely private house equipped with everything you need to fully enjoy your vacation in Jacó, located 
                at an 8-minute drive to Jaco town and the beach. Inside a valley, this new developing community is very safe 
                and surrounded by nature, mountain views, and the beautiful sounds of the jungle.
            </p>
            <p>In this private beach home, you will enjoy:</p>
            <ul>
                <li>Private resort style with LED lights that belong to this house only.</li>
                <li>Sleeps up to 10 people.</li>
                <li>
                    The first floor consists of an open space with a living room, full kitchen, dining room, bedroom, and a 
                    half bathroom. This floor has a very cool sliding access to the pool area.
                </li>
                <li>
                    The second floor has 3 bedrooms, 2 full bathrooms, a laundry room, and balconies. 
                    <strong>Distribution of rooms and beds:</strong>
                    <ul>
                        <li><strong>Master Room 1:</strong> Has a king bed, balcony, private full bathroom, and walk-in closet.</li>
                        <li><strong>Room 2:</strong> Has a queen bed and balcony.</li>
                        <li>
                            <strong>Room 3:</strong> Has a queen bed and a bunk bed (single on top and queen at the bottom).
                        </li>
                        <li><strong>Room 4:</strong> Has a double bed.</li>
                    </ul>
                </li>
                <li>
                    Every room has a safe, towels, hangers, dresser, sheets, pillows, and blanket. Bathrooms have shampoo, 
                    shower gel, and conditioner, royal top line.
                </li>
                <li>Parking for up to 4 cars.</li>
                <li>Air conditioning in all rooms and the first floor.</li>
                <li>Gas grill and outdoor shower.</li>
            </ul>
            <p>
                The town, Jaco, is the closest developed beach town to the international airport Juan Santamaria, where you 
                can find world-class surfing, fishing, restaurants, golf, and more. This lovely beach town is located at 
                the Pacific Central Coast where the rainforest welcomes you with wildlife beauty, safe adventures for 
                everyone, and the comfort of a country that in recent years has exceeded expectations with great service 
                and a true pura vida vibe.
            </p>
            <p>
                Also, the most popular and premium adventure park, Los Sueños Adventure Park, is just a 5-minute drive from 
                the house, perfect for enjoying zip lining, ATV tours, waterfalls, restaurants, and more. 
            </p>
            <p>
                Find more activities in the area like hikes, horseback riding, beach, national parks, a variety of 
                restaurants, boutique shops, grocery stores, and nearby, the famous marina, Los Sueños, just 8 minutes drive.
            </p>
            <p>
                While staying with us, we can assist with providing a variety of services such as private transportation, 
                food delivery, chefs, bartenders, decorations, restaurant reservations, tours/excursions, in-home massages, 
                nightlife concierge, and bike rentals. These services come with additional costs.
            </p>
            <p>
                Regardless of whether you are looking for a location that's quiet or just minutes to downtown, rest 
                assured the Zen properties are a home run.
            </p>`,
      "room_distribution": {
        "master_room": {
          "bed_type": "King bed",
          "features": "Balcony, private full bathroom, walk-in closet"
        },
        "room_2": {
          "bed_type": "Queen bed",
          "features": "Balcony"
        },
        "room_3": {
          "bed_type": "Queen bed and bunk bed",
          "features": "Single on top, queen at bottom"
        },
        "room_4": {
          "bed_type": "Double bed"
        }
      },
      "check_in_time": "4pm",
      "check_out_time": "11am"
    },
    {
        "property_id": "81768",
        "property_title": "Private pool & rooftop patio Hot tub, AC, WI-FI",
        "website_name": "Zen Villa 2",
        "property_name": "Zen Villa Beach",
        "location": "Jaco, Jaco",
        "maps": "insert_map_address_for_xframe",
        "type": "Private beach home",
        "amenities": {
          "oven": "Oven",
          "microwave": "Microwave",
          "toaster": "Toaster",
          "coffee_maker": "Coffee Maker",
          "dishes_utensils": "Dishes & Utensils",
          "dishwasher": "Dishwasher",
          "kitchen": "Kitchen",
          "pantry_items": "Pantry Items",
          "refrigerator": "Refrigerator",
          "stove": "Stove",
          "internet": "Internet",
          "air_conditioning": "Air Conditioning",
          "washing_machine": "Washing Machine",
          "clothes_dryer": "Clothes Dryer",
          "linens": "Linens Provided",
          "towels": "Towels Provided",
          "free_parking_on_premises": "Free parking on premises",
          "free_street_parking": "Free street parking",
          "iron_board": "Iron & Board",
          "television": "Television",
          "toys": "Toys",
          "lawn_garden": "Lawn / Garden",
          "fire_extinguisher": "Fire extinguisher",
          "smoke_detector": "Smoke detector",
          "gas_grill": "Gas barbecue grill"
        },
        "bedrooms": 2,
        "beds": 3,
        "bathrooms": 2,
        "max_guests": 6,
        "short_description": "Welcome to Zen Villa 2, a completely private house with its own private pool equipped with everything you need to fully enjoy your vacation in Jacó.",
        "description": `
          <h2>Welcome to Zen Villa 2</h2>
          <p>A completely private house with its own private pool equipped with everything you need to fully enjoy your vacation in Jacó, located at an 8-minute drive to Jaco town and the beach. Inside a valley, this new developing community surrounds itself by nature, mountain views, and beautiful jungle sounds.</p>
          <p>In this private beach home, you will enjoy:</p>
          <ul>
            <li>Private pool with LED lights that belong to this house only.</li>
            <li>Sleeps up to 6 people.</li>
            <li>The first floor consists of an open space with a living room, full kitchen, dining room, and 2 bedrooms with 2 bathrooms. Both bedrooms have sliding access to the pool area.</li>
            <li>The second floor is a rooftop patio with a Jacuzzi, hammock net bed, mini pergola, and beautiful mountain views.</li>
          </ul>
          <p>Just 8 minutes from the property towards Herradura, you will find La Marina Los Sueños, a luxury community with public access to the golf course, shopping center, restaurants, and coffee shop.</p>
          <p>Also nearby is Los Sueños Adventure Park for zip-lining, ATV tours, waterfalls, hikes, horseback riding, and more. Jaco Beach and its vibrant town offer souvenir shops, restaurants, bars, and clubs.</p>
          <p>While staying with us, we can assist with services like private transportation, chefs, bartenders, restaurant reservations, tours, in-home massages, and more.</p>
        `,
        "room_distribution": {
          "master_bedroom": {
            "bed_type": "Queen bed",
            "features": "Sliding access to pool, TV, private full bathroom"
          },
          "double_bedroom": {
            "bed_type": "2 Queen beds",
            "features": "Sliding access to pool, TV, full bathroom with 2 shower heads"
          }
        },
        "check_in_time": "4pm",
        "check_out_time": "11am"
    },
    {
        "property_id": "81769",
        "property_title": "2 Kitchens, 2 private pools, rooftop tub, AC, Wi-Fi",
        "website_name": "Zen Villa 2",
        "property_name": "Zen Villa Beach",
        "location": "Jaco, Jaco",
        "maps": "insert_map_address_for_xframe",
        "type": "Private beach home",
        "amenities": {
            "oven": "Oven",
            "microwave": "Microwave",
            "toaster": "Toaster",
            "coffee_maker": "Coffee Maker",
            "dishes_utensils": "Dishes & Utensils",
            "dishwasher": "Dishwasher",
            "kitchen": "Kitchen",
            "pantry_items": "Pantry Items",
            "refrigerator": "Refrigerator",
            "stove": "Stove",
            "internet": "Internet",
            "air_conditioning": "Air Conditioning",
            "washing_machine": "Washing Machine",
            "clothes_dryer": "Clothes Dryer",
            "linens": "Linens Provided",
            "towels": "Towels Provided",
            "free_parking_on_premises": "Free parking on premises",
            "free_street_parking": "Free street parking",
            "hair_dryer": "Hair Dryer",
            "iron_board": "Iron & Board",
            "television": "Television",
            "lawn_garden": "Lawn / Garden",
            "outdoor_grill": "Outdoor Grill",
            "fire_extinguisher": "Fire extinguisher",
            "smoke_detector": "Smoke detector"
        },
        "bedrooms": 6,
        "beds": 8,
        "bathrooms": 5,
        "max_guests": 16,
        "short_description": `<h2>Welcome to Zen Villa 2</h2>
            <p>
                Two beautiful homes that connect with each other, with a sliding gate when both rent together. Each house 
                is independent with full amenities, completely private property equipped with everything you need to fully 
                enjoy your vacation in Jacó, located at an 8-minute drive to Jaco town and the beach. Inside a valley, this 
                new developing community is very safe and surrounded by nature, mountain views, and beautiful sounds of the 
                jungle.
            </p>`,
        "description": `
            <h2>Welcome to Zen Villa 2</h2>
            <p>
                Two beautiful homes that connect with each other, with a sliding gate when both rent together. Each house 
                is independent with full amenities, completely private property equipped with everything you need to fully 
                enjoy your vacation in Jacó, located at an 8-minute drive to Jaco town and the beach. Inside a valley, this 
                new developing community is very safe and surrounded by nature, mountain views, and beautiful sounds of the 
                jungle.
            </p>
            <p>
                Perfect for groups of up to 16 people, great for family, friends, and pets.
            </p>
            <h3>Home 1:</h3>
            <p>
                In this private beach home, you will enjoy:
            </p>
            <ul>
                <li>Private resort style with LED lights that belong to this house only.</li>
                <li>Sleeps up to 10 people.</li>
                <li>
                    The first floor consists of an open space with a living room, full kitchen, dining room, bedroom, and a 
                    half bathroom. This floor has a very cool sliding access to the pool area.
                </li>
                <li>
                    The second floor has 3 bedrooms, 2 full bathrooms, a laundry room, and balconies.
                    <strong>Distribution of rooms and beds:</strong>
                    <ul>
                        <li><strong>Master Room 1:</strong> Has a king bed, balcony, private full bathroom, and walk-in closet.</li>
                        <li><strong>Room 2:</strong> Has a queen bed and balcony.</li>
                        <li><strong>Room 3:</strong> Has a queen bed and a bunk bed (single on top and queen at the bottom).</li>
                        <li><strong>Room 4:</strong> Has a double bed.</li>
                    </ul>
                </li>
                <li>
                    Every room has a safe, towels, hangers, dresser, sheets, pillows, and blanket. Bathrooms have shampoo, 
                    shower gel, and conditioner, royal top line.
                </li>
                <li>Parking for up to 4 cars.</li>
                <li>Air conditioning in all rooms and first floor, gas grill, and outdoor shower.</li>
            </ul>
            <h3>Home 2:</h3>
            <p>
                In this private beach home, you will enjoy:
            </p>
            <ul>
                <li>Private pool with LED lights that belong to this house only.</li>
                <li>Sleeps up to 6 people.</li>
                <li>
                    The first floor consists of an open space with a living room, full kitchen, dining room, and 2 bedrooms 
                    with 2 bathrooms. Both bedrooms have very cool sliding access to the pool area.
                </li>
                <li>
                    The second floor is a rooftop patio with a Jacuzzi, hammock net bed, mini pergola, and beautiful mountain views.
                    <strong>Distribution of rooms and beds:</strong>
                    <ul>
                        <li><strong>Master Bedroom:</strong> Has a queen bed, TV, sliding access to pool, and private full bathroom.</li>
                        <li><strong>Double Bedroom:</strong> Has 2 queen beds, TV, sliding access to the pool area, and full bathroom with 2 cool shower heads next door.</li>
                    </ul>
                </li>
                <li>
                    Every room has a safe, towels, hangers, dresser/clothing rack, night tables, sheets, pillows, and blanket.
                    Bathrooms have shampoo, shower gel, and conditioner, royal line, free of parabens, organic aromatherapy.
                </li>
                <li>Parking, shaded parking for 1 car.</li>
                <li>Gas barbecue grill.</li>
            </ul>
            <p>
                The town, Jaco, is the closest developed beach town to the international airport Juan Santamaria, where you 
                can find world-class surfing, fishing, restaurants, golf, and more. This lovely beach town is located at 
                the Pacific Central Coast where the rainforest welcomes you with wildlife beauty, safe adventures for 
                everyone, and the comfort of a country that in recent years has exceeded expectations with great service 
                and a true pura vida vibe.
            </p>
            <p>
                While staying with us, we can assist with providing a variety of services such as private transportation, 
                food delivery, chefs, bartenders, decorations, restaurant reservations, tours/excursions, in-home massages, 
                nightlife concierge, and bike rentals. These services come with additional costs.
            </p>
            <p>
                Regardless of whether you are looking for a location that's quiet or just minutes to downtown, rest 
                assured the Zen properties are a home run.
            </p>
        `,
        "room_distribution": {
            "master_room": {
                "bed_type": "King bed",
                "features": "Balcony, private full bathroom, walk-in closet"
            },
            "room_2": {
                "bed_type": "Queen bed",
                "features": "Balcony"
            },
            "room_3": {
                "bed_type": "Queen bed and bunk bed",
                "features": "Single on top, queen at bottom"
            },
            "room_4": {
                "bed_type": "Double bed"
            },
            "room_5": {
                "bed_type": "Queen bed",
                "features": "TV, sliding access to pool, private full bathroom"
            },
            "room_6": {
                "bed_type": "2 Queen beds",
                "features": "TV, sliding access to pool area, full bathroom with 2 shower heads"
            }
        },
        "check_in_time": "4pm",
        "check_out_time": "11am"
    },
    {
        "property_id": "81620",
        "title": "Ocean View, Infinity Pool, Lux Game/Bar Room & Gym",
        "location": "Plantation Acres, Propiedad 2, Quebrada Ganado",
        "bedrooms": 4,
        "beds": 7,
        "max_guests": 12,
        "bathrooms": 4,
        "short_description": "Enjoy million-dollar views of the Pacific Ocean & jungle from the infinity pool along with every room in the house.",
       "description": `
    <div style="font-family: Arial, sans-serif; line-height: 1.6; padding: 20px; background-color: #12192B;; border-radius: 8px;">
        <h1 style="text-align: center; color: #2c3e50;">Welcome to Vida Palace</h1>
        <p>A luxury modern new build designer home located in the jungle just minutes to top beaches, restaurants, ziplining, and outdoor activities. We designed the home around the ocean view, so regardless of what room you are in, you will find large windows with 180-degree views in both the Master 1 and the kitchen/living room. The house comes fully stocked with towels, beach towels, and the kitchen has stainless steel appliances &amp; everything needed for just a stay in and cook night, all the way up to private chef events. The house has strong Wi-Fi and a networking system, so regardless of if you are poolside, in the game room, or enjoying our spacious rooms, you will have fast and constant Wi-Fi.</p>
        
        <h2>Our Rooms</h2>
        <p>Each room has brand new A/C, strong Wi-Fi, new G-Play flatscreen TV &amp; OCEAN VIEWS. We use new linens on all bedding and follow all health/safety guidelines.</p>
        
        <h3>Bedroom 1</h3>
        <p>Upper Master- King with lighted gypsum decor, private bathroom with roman tub- rain style shower, 2 private patios, 180-degree views, safe, G-play flat screen TV, A/C. Master closet Gym sized locker x2 (Larger items).</p>
        
        <h3>Bedroom 2</h3>
        <p>Upper Master- Queen x2 with ocean view, large patio, private bathroom with rain style shower, safe, G-play flatscreen TV, A/C.</p>
        
        <h3>Bedroom 3</h3>
        <p>1st Floor- Queen x2 with ocean view, walk out deck with pool access, safe, flatscreen TV, A/C.</p>
        
        <h3>Bedroom 4</h3>
        <p>1st Floor- Queen x2 with ocean view, walk out deck with pool access, safe, flatscreen TV, A/C.</p>
        
        <p>The main patio is massive and wraps the entire backside of the home- You will find the infinity pool, outdoor shower, BBQ grill, outdoor bed, lounge chairs, and several outdoor ceiling fans in this area.</p>
        
        <h2>Game/Bar Room</h2>
        <p>(banquet hall) is a multipurpose room that you can turn into a night dancing club. This is a separate wing of the home. The game room has a large 85" TV, sofa, bar stools, arcade games, shuffleboard, ping pong, full wet bar, and has night Club style LED lights and sound system. In a separate back room, you will find a small personal gym with a Nordic treadmill, TRX cables, free weights, and a TV.</p>
        
        <h2>Event Setup</h2>
        <p>The banquet hall can be set up for:</p>
        <ul>
            <li>Birthdays</li>
            <li>Weddings</li>
            <li>Bachelor parties</li>
            <li>Bachelorette parties</li>
            <li>Yoga retreat</li>
            <li>Corporate events</li>
        </ul>
        
        <p>Below the home, you find a multi-function area to include a private pickleball court and half basketball court.</p>
        
        <p>Luxury ultra-safe gated community located off the main highway (34) just outside of Jaco. The average lot size is over 2 acres so you will enjoy the feeling of being out in the country with amazing jungle views and awesome wildlife. We often see Macaws, Toucans, Hummingbirds, Turkeys &amp; Monkeys making this area a very special place in terms of nature. The main entrance is gated, and our property is less than a 5 min walk to several restaurants, pharmacy, and Riders ATV station.</p>
    </div>
        `, 
       "amenities": {
    "oven": "Oven",
    "microwave": "Microwave",
    "toaster": "Toaster",
    "coffee_maker": "Coffee Maker",
    "dishes_utensils": "Dishes & Utensils",
    "dishwasher": "Dishwasher",
    "kitchen": "Kitchen",
    "pantry_items": "Pantry Items",
    "refrigerator": "Refrigerator",
    "stove": "Stove",
    "internet": "Internet",
    "air_conditioning": "Air Conditioning",
    "washing_machine": "Washing Machine",
    "clothes_dryer": "Clothes Dryer",
    "linens": "Linens Provided",
    "towels": "Towels Provided",
    "free_parking_on_premises": "Parking",
    "free_parking_on_premises": "Free parking on premises",
    "free_street_parking": "Free street parking",
    "hair_dryer": "Hair Dryer",
    "iron_board": "Iron & Board",
    "fitness": "Fitness Room / Equipment",
    "living_room": "Living Room",
    "television": "Television",
    "game_room": "Game Room",
    "ping_pong": "Ping Pong Table",
    "foosball": "Foosball",
    "games": "Games",
    "cable_tv": "Satellite / Cable",
    "stereo": "Stereo",
    "video_games": "Video Games",
    "pool": "Private Pool",
    "outdoor_grill": "Outdoor Grill",
    "internet": "Free wifi",
    "iron_board": "Cleaned with disinfectant",
    "fire_extinguisher": "Fire extinguisher",
    "smoke_detector": "Carbon monoxide detector",
    "smoke_detector": "Smoke detector"
    },
        "check_in_time": "3pm",
        "check_out_time": "11am",
        "room_distribution": {
            "upper_master_1": {
                "bed_type": "King bed",
                "features": "Private bathroom with roman tub, rain style shower, 2 private patios, 180-degree views, safe, G-play flat screen TV, A/C."
            },
            "upper_master_2": {
                "bed_type": "Queen x2",
                "features": "Ocean view, large patio, private bathroom with rain style shower, safe, G-play flatscreen TV, A/C."
            },
            "first_floor_room_1": {
                "bed_type": "Queen x2",
                "features": "Ocean view, walk out deck with pool access, safe, flatscreen TV, A/C."
            },
            "first_floor_room_2": {
                "bed_type": "Queen x2",
                "features": "Ocean view, walk out deck with pool access, safe, flatscreen TV, A/C."
            }
        }
    }
];
  
const PropertyCard = ({ propertyId }) => {
    const [showMore, setShowMore] = useState(false);
    const toggleDescription = () => setShowMore(!showMore);

    // Find the property based on propertyId
    const property = properties.find(prop => prop.property_id === propertyId);

    if (!property) {
        return <div>Property not found</div>;
    }

    return (
        <div className="property-card">
            <h2>{property.property_title}</h2>
            <h3>{property.property_name}</h3>
            <h4>{property.location}</h4>
            <div className="top-section">
                <div className="amenities">
                    <div className="amenity">
                        {getSvg('bedrooms')}
                        <span>{property.bedrooms} Bedrooms</span>
                    </div>
                    <div className="amenity">
                        {getSvg('beds')}
                        <span>{property.beds} Beds</span>
                    </div>
                    <div className="amenity">
                        {getSvg('bathrooms')}
                        <span>{property.bathrooms} Bathrooms</span>
                    </div>
                    <div className="amenity">
                        {getSvg('guests')}
                        <span>{property.max_guests} Guests</span>
                    </div>
                </div>
            </div>
            <div className="description">
                <p>
                    {showMore 
                        ? <span dangerouslySetInnerHTML={{ __html: property.description }} />
                        :  <span dangerouslySetInnerHTML={{ __html: property.short_description }} /> }
                </p>
                <button onClick={toggleDescription}>
                    {showMore ? 'Show Less' : 'Show More'}
                </button>
            </div>
            <div className="amenities-list">
                <h5>Amenities</h5>
                <div className="amenities-container">
                    {Object.entries(property.amenities).map(([key, value]) => (
                        <div key={key} className="amenity-item">
                            {getSvg(key)}
                            <span>{value}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className="bottom-section">
                <div className="check-in-out">
                    <div className="time">
                        <span>Check-In: {property.check_in_time}</span>
                    </div>
                    <div className="time">
                        <span>Check-Out: {property.check_out_time}</span>
                    </div>
                </div>
            </div>
            <div className="map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18..."
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    title="Google Map of Jacó, Costa Rica"
                ></iframe>
            </div>
        </div>
    );
};

export default PropertyCard;
