import axios from 'axios';

// Function to fetch calendar data from your WordPress backend
export const fetchCalendar = async (listingId, from, to) => {
  try {
    const response = await axios.get('/wp-admin/admin-ajax.php', {
      params: {
        action: 'my_plugin_fetch_calendar',
        listingId: listingId,
        from: from,
        to: to,
      },
    });

    // Ensure the response contains data
    if (response.data.success) {
      return response.data.data; // Return the full data object
    } else {
      console.log('Error with data:', response.data.data);
      throw new Error(response.data.data); // Handle error returned from server
    }
  } catch (error) {
    console.error('Error fetching calendar data:', error);
    throw error; // Re-throw error to handle it in your component
  }
};
